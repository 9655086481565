import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
  const [user, setUser] = useLocalStorage("user", userData);
  const [userLogin, setUserLogin] = useLocalStorage("userLogin", {});

  const navigate = useNavigate();

  const login = async (loginData) => {
    let data = JSON.stringify({
      identifier: loginData.email,
      password: loginData.password,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch("https://strapi.trituenhantao.xyz/api/auth/local", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.error) {
          // console.log(result);
          setUser(result);
          setUserLogin(loginData);
          navigate("/dashboard/chooseGame", { replace: true });
        } else {
          console.log(result.error);
          toast("Kiểm tra lại thông tin đăng nhập");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const checkLogin = async (loginData, callback) => {
    let data = JSON.stringify({
      identifier: loginData.email,
      password: loginData.password,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch("https://strapi.trituenhantao.xyz/api/auth/local", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.error) {
          // console.log(result);
          setUser(result);
          if (callback) {
            callback(result);
          }
        } else {
          console.log(result.error);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const register = async (loginData) => {
    let data = JSON.stringify({
      email: loginData.email,
      password: loginData.password,
      username: loginData.username,
      PhoneNumber: loginData.PhoneNumber,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow",
    };

    fetch(
      "https://strapi.trituenhantao.xyz/api/auth/local/register",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (!result.error) {
          // console.log(result);
          setUser(result);
          navigate("/dashboard/chooseGame", { replace: true });
        } else {
          console.log(result.error);
          toast("Kiểm tra lại thông tin đăng ký");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      register,
      checkLogin,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
